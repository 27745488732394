$primary: #e50914;
$secondary:#1a1a1a;

$fontFamily:sarala;

$text:white;
$muted-text:rgba(255, 255, 255, 0.3);
$input-bg:#C4C4C4;

$border-radius: 5px;
$btn-border-radius-lg: 2px;
$btn-border-radius-sm: 2px;
$trans: all .5s;

$font-bg: 20px;
$font-xl: 30px;

$navbar-base-height: 56px;
$sidebar-base-width: 200px;
$sidebar-collapsed-width: 90px;
$sticky-footer-height: 50px;


@mixin centered {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

@mixin flexed {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin btn {
    border: 1px solid $secondary;
    border-radius: $border-radius;
    background: $primary/1;
    text-transform: capitalize;
    padding: .5em 1.5em;
    color: $text;
    font-size: 20px;
    font-weight:bold;
    transition: $trans;
    outline: none;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    img{
        width: 12%;
        transition: $trans;
    }
    i{
         margin: 0 .5em;
    }
    &:hover{
        background: $primary/1.5;
        img{
            transform: translateX(30%);
        }
    }
    @media screen and (max-width:800px) {
        font-size: 13px;
    }
}

@mixin logo {
    font-size: 35px;
    color: $primary;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    background: transparent;
    cursor: pointer;
    padding: 0;
    text-shadow: none;
    outline: none;
    @media screen and (max-width:1600px) {
        font-size: 25px;
    }
    
    &:hover{
        background: none;
    }
}

@mixin linkText {
  color: #0063ccc5;
  transition: $trans;
  &:hover {
    color: #0063ccc5;
  }
}

