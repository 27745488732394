*{
  @import url("https://fonts.googleapis.com/css?family=Sarala&display=swap");
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: linear-gradient($secondary 20%,black );
  color: $text !important;
  font-family: "Sarala", sans-serif !important;
  @media screen and (max-width:800px) {
    background: linear-gradient($secondary 20%,black );
  }
}


.text-lg {
  font-size: $font-bg;
}

.text-xl {
  font-size: $font-bg;
  line-height: 1.2;

  @include media-breakpoint-up(lg) {
    font-size: $font-xl;
  }
}

.nohover:hover {
  background: transparent !important;
  color: white !important;
}

.animation-container {
  position: relative;
  min-height: 300px;

  @include media-breakpoint-up(lg) {
    min-height: 400px;
  }
}

.navbar-dark {
  transition: background-color 200ms linear;
}

.hSection {
  min-height: 90vh !important;
}

.hFullSection {
  min-height: 100vh !important;
}

@media screen and (min-width: 1400px) {
  .nextGrid {
    max-width: 400px;
  }
}

.icon {
  position: absolute;
  width: 100%;
  margin-top: 20%;
  display: block;
  color: white;
  font-size: 1.2em;
  -webkit-transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  &:hover {
    color: $primary;
  }
}

#circ {
  max-width: 100%;
  width: 500px;
  margin: 0 auto;
}
#circ ul {
  list-style: none;
}
#circ ul li {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  width: 45px;
  height: 45px;
  margin: 0 15px 15px 15px;
  text-align: center;
  background: black;
  border: solid 2px $primary;
  position: relative;
  transition: all 0.4s;
}
#circ ul li:hover {
  border: solid 2px white;
  transition: all 0.4s;
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  .icon {
    position: relative;
    margin-top: 10%;
    color: $primary;
    font-size: 1.3em;
    -webkit-transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    -moz-transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  #circ ul li {
    width: 70px;
    height: 70px;
    margin: 0 5px 5px 5px;
  }
  #circ ul li .icon {
    font-size: 1.5em;
    color: white;
  }
  #circ ul li:hover .icon {
    color: $primary;
  }
}
@media only screen and (max-width: 690px) {
  #circ ul li {
    border-radius: 0;
    border: none;
    margin: 0;
  }
  #circ ul li:hover {
    border: none;
  }
}
