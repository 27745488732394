.dashboard-background {
  background: url("../../assets/svg/payment.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  min-height: calc(100vh - 24px);
  max-width: 100vw;

  .short-border-line {
    width: 54px;
    height: 2px;
    background-color: $primary;
    margin-left: 2px;
    margin-top: 2px;
  }
  .dashboard-header {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    color: $primary;
    margin-top: 24px;
    @media (max-width: 832px) {
      width: auto;
      max-width: 800px;
      margin-left: 16px;
      margin-right: 16px;
    }
    .dashboard-logo {
      h1 {
        font-size: 35px;
        margin: 0;
      }
      p {
        font-size: 14px;
      }
    }

    button {
      background-color: $primary;
      border: none;
      padding: 4px 8px;
      display: block;
      height: max-content;
      border-radius: 5px;
      color: white;
    }
  }

  .dashboard-promocode {
    margin-left: auto;
    margin-right: auto;
    margin-top: 64px;
    background: #fbfbfb;
    width: fit-content;
    height: 99px;
    border: 1px solid #c9c9c9;
    box-sizing: border-box;
    box-shadow: 2px 6px 8px rgba(136, 136, 136, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    @media (max-width: 418px) {
      margin-left: 16px;
      margin-right: 16px;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      margin-right: 32px;
      margin-left: 32px;
      color: #5e5e5e;
      margin-bottom: 0;
      @media (max-width: 418px) {
        margin-left: 16px;
        margin-right: 16px;
      }
      @media (max-width: 370px) {
       width: fit-content;
      }
      span {
        font-family: Sarala;
        font-style: normal;
        font-weight: normal;
        font-size: 32px;
        color: #e50914;
        display: inline-block;
        margin-left: 8px;
        position: relative;
        top: 4px;
      }
    }
  }

  .dashboard-stats {
    width: 514px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 32px;
    @media (max-width: 546px) {
      width: auto;
      max-width: 514px;
      margin-left: 16px;
      margin-right: 16px;
    }
    h2 {
      font-size: 24px;
    }
    .dashbord-section__title .short-border-line {
      background: white;
    }
    .dashboard-stats__section {
      width: 514px;
      height: 219px;
      background: #fbfbfb;
      border: 1px solid #c9c9c9;
      box-sizing: border-box;
      box-shadow: 2px 6px 8px rgba(136, 136, 136, 0.25);
      border-radius: 5px;
      margin-top: 16px;
      display: flex;
      align-items: center;
      @media (max-width: 546px) {
        width: auto;
        max-width: 514px;
        flex-direction: column;
        height: fit-content;
        & > div:first-child {
          margin-top: 16px;

        }
        & > div:nth-child(2) {
          transform: rotate(90deg);
        }
      }
      & > div:first-child {
        margin-right: 36px;
        margin-left: 32px;
        font-size: 24px;
        color: #555555;
        min-width: 200px;
        & > div {
          width: 100%;
          display: flex;
          justify-content: space-between;
          p > span {
            text-align: end;
            display: inline-block;
            margin-left: 48px;
          }
        }
      }
      & > div:nth-child(2) {
        height: 125px;
        width: 1px;
        border: 1px solid #d7d7d7;
      }
      & > div:nth-child(3) {
        margin-left: 36px;
        margin-right: 34px;
        h4 {
          font-family: Sarala;
          font-style: normal;
          font-weight: normal;
          font-size: 24px;
          color: #555555;
        }
        p {
          font-family: Sarala;
          font-style: normal;
          font-weight: normal;
          font-size: 48px;
          color: #e50914;
          text-align: center;
          margin-top: 12px;
        }
      }
    }
  }
}
